const Gallery=[
    {
        id:1,
        image:"Image/1.jpg",
        name:"class10",
        category:"class8"
    },
     
    {
        id:1,
        image:"Image/2.jpg",
        name:"class10",
        category:"class8"
    },
    
    {
        id:1,
        image:"Image/3.jpg",
        name:"class10",
        category:"class4"
    },
    
    {
        id:1,
        image:"Image/4.jpg",
        name:"class10",
        category:"class4"
    },
    {
        id:1,
        image:"Image/5.jpg",
        name:"class10",
        category:"class8"
    },
    
    {
        id:1,
        image:"Image/6.jpg",
        name:"class10",
        category:"class6"
    },
    
    {
        id:1,
        image:"Image/7.jpg",
        name:"class10",
        category:"class4"
    },
    
    {
        id:1,
        image:"Image/8.jpg",
        name:"class10",
        category:"class2"
    },
    {
        id:1,
        image:"Image/9.jpg",
        name:"class10",
        category:"class8"
    },
    
    {
        id:1,
        image:"Image/10.jpg",
        name:"class10",
        category:"class6"
    },
    
    {
        id:1,
        image:"Image/11.jpg",
        name:"class10",
        category:"class4"
    },
    
    {
        id:1,
        image:"Image/12.jpg",
        name:"class10",
        category:"class2"
    },
    {
        id:1,
        image:"Image/13.jpg",
        name:"class10",
        category:"class8"
    },
    
    {
        id:1,
        image:"Image/14.jpg",
        name:"class10",
        category:"class6"
    },
    
    {
        id:1,
        image:"Image/15.jpg",
        name:"class10",
        category:"class4"
    },
    
    {
        id:1,
        image:"Image/12.jpg",
        name:"class10",
        category:"class2"
    },
    
    
    
    
    
]

export default Gallery;