import React from 'react'

const Map = () => {
    return (
       <>
        <div style={{width: '100%'}}><iframe title="khalid" width="100%" height={360} frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} src="https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Sadh%20Nagar%202%20Sadh%20Nagar%20II,%20Sadh%20Nagar,%20Palam,%20New%20Delhi,%20Delhi%20110045+(KBP%20SCHOOL)&t=&z=14&ie=UTF8&iwloc=B&output=embed" /></div>
       </>
    )
}

export default Map
