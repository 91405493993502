import React from 'react'

const WhyChoseUs = () => {
    return (
      <section style={{ backgroundImage: 'url("assets/images/kkkk.webp")', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat', borderRadius: '20px', backgroundPosition: 'center', backgroundSize: 'cover' }} className="small_pb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="text-center animation" data-animation="fadeInUp" data-animation-delay="0.01s">
              <div className="heading_s1 text-center">
                <h2 style={{ color: 'green' }}>Why Choose Us</h2>
              </div>
              <p style={{ color: 'white' }} >If you are going to use a passage of you need to be sure there isn't anything embarrassing hidden in the middle of text</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div style={{ borderRadius: '20px' }} className="icon_box icon_box_style1 animation" data-animation="fadeInUp" data-animation-delay="0.02s">
              <div className="box_icon mb-3">
                <i className="fa fa-book text_default" />
              </div>
              <div className="intro_desc">
                <h5 style={{ color: 'white' }} >Books &amp; Library</h5>
                <p style={{ color: 'white' }}>If you are going to use a passage of anything embarrassing hidden in the middle of text</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon_box icon_box_style1 animation" data-animation="fadeInUp" data-animation-delay="0.03s">
              <div className="box_icon mb-3">
                <i className="fa fa-globe text_default" />
              </div>
              <div className="intro_desc">
                <h5 style={{ color: 'white' }} >Learn Courses Online</h5>
                <p style={{ color: 'white' }} >If you are going to use a passage of anything embarrassing hidden in the middle of text</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon_box icon_box_style1 animation" data-animation="fadeInUp" data-animation-delay="0.04s">
              <div className="box_icon mb-3">
                <i className="fa fa-user-tie text_default" />
              </div>
              <div className="intro_desc">
                <h5 style={{ color: 'white' }} >Expert Instructors</h5>
                <p style={{ color: 'white' }} >If you are going to use passage of anything embarrassing hidden in the middle of text</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon_box icon_box_style1 animation" data-animation="fadeInUp" data-animation-delay="0.05s">
              <div className="box_icon mb-3">
                <i className="fa fa-child text_default" />
              </div>
              <div className="intro_desc">
                <h5 style={{ color: 'white' }} >Kids Education</h5>
                <p style={{ color: 'white' }} >If you are going to use passage of anything embarrassing hidden in the middle of text</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon_box icon_box_style1 animation" data-animation="fadeInUp" data-animation-delay="0.06s">
              <div className="box_icon mb-3">
                <i className="fa fa-headphones-alt text_default" />
              </div>
              <div className="intro_desc">
                <h5 style={{ color: 'white' }}>music Programs</h5>
                <p style={{ color: 'white' }} >If you are going to use passage of anything embarrassing hidden in the middle of text</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="icon_box icon_box_style1 animation" data-animation="fadeInUp" data-animation-delay="0.07s">
              <div className="box_icon mb-3">
                <i className="fa fa-graduation-cap text_default" />
              </div>
              <div className="intro_desc">
                <h5 style={{ color: 'white' }} >Scholarship</h5>
                <p style={{ color: 'white' }} >If you are going to use passage of anything embarrassing hidden in the middle of text</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}

export default WhyChoseUs
